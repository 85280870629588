import React from "react"
import { graphql } from "gatsby"

//import Bio from "../components/bio"
import Layout from "../components/layout"
//import ReadMore from "../components/read-more"
//import Share from "../components/share"
//import Topics from "../components/topics"
// import SEO from "../components/seo"

const InitiativePostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
    <section class="hero is-halfheight hero-banner" id="initiative-hero">
          <div class="hero-body">
          </div>
    </section>            
      <main>
        <article itemScope itemType="http://schema.org/Article">
          <header>
            <h1 itemProp="headline">
              {post.frontmatter.title}
            </h1>
          </header>

          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
      </main>
    </Layout>
  )
}

export default InitiativePostTemplate

export const pageQuery = graphql`
  query InitiativePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title,
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields { slug }
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        topics
      }
    }
  }
`